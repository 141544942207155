import React, { useContext } from "react";
import { inject, observer } from "mobx-react";
import { Card, Cascader, Col, Form, Icon, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import Upload from "../../common/Upload";
import Field from "../../common/FieldContainer";
import EditableStatus from "../../common/EditableStatus";
import { toCascaderOptions, toOptionArray } from "../../helpers/array-utils";
import FormContext from "../../models/FormContext";
import Switch from "../../common/Switch";

function MainInfo({
  store: {
    story,
    selectOptions: { categories = [], contentTypes = [], statuses = [] }
  },
  store
}) {
  const formState = useContext(FormContext);

  const removeAttachmentHandler = async attachmentId => {
    await store.deleteFile(attachmentId);

    // eslint-disable-next-line array-callback-return
    await formState.$("attachments").map(field => {
      if (field.value.id === attachmentId) {
        formState.$("attachments").del(field.key);
      }
    });
  };

  const onFileUploadChange = fileList => {
    if (formState.$("attachments").value) {
      return formState.$("attachments").value.push(fileList);
    }
    formState.$("attachments").value = [fileList];
    return null;
  };

  const displayCascaderRender = labels => {
    return <span>{labels.join(` › `)}</span>;
  };

  return (
    <Card
      className="story-panel story-information-container no-padding"
      bordered={false}
      title="Story Information"
    >
      <Row gutter={70} className="create-story-row">
        <Col lg={8} md={24}>
          <Field name="title">
            <Input />
          </Field>
        </Col>
        <Col lg={8} md={24}>
          <Field name="category_id" style={{ width: "100%" }}>
            <Cascader
              options={toCascaderOptions(categories)}
              placeholder="Select Category or Type"
              suffixIcon={<Icon type="caret-down" theme="filled" />}
              displayRender={displayCascaderRender}
              popupClassName="cascader-popup"
            />
          </Field>
        </Col>
        <Col lg={8} md={24}>
          <Field name="content_type_id" style={{ width: "100%" }}>
            <Select
              suffixIcon={<Icon type="caret-down" theme="filled" />}
              allowClear
            >
              {toOptionArray(contentTypes).map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Field>
        </Col>
      </Row>
      <Row gutter={70} className="create-story-row">
        <Col lg={8} md={24}>
          <Field name="note">
            <TextArea rows={3} />
          </Field>
        </Col>
        <Col lg={8} md={24}>
          <Field
            name="attachments"
            style={{ width: "100%" }}
            label="Story Attachments"
          >
            <Upload
              uploadButtonLabel="Upload"
              uploadButtonSize="small"
              uploadButtonTheme="blue-outline"
              attachedFiles={story.fileList}
              onRemove={removeAttachmentHandler}
              onFileUploadChange={onFileUploadChange}
            />
          </Field>
        </Col>
        <Col lg={8} md={24}>
          <Form.Item colon={false} style={{ width: "100%" }} label="Status">
            <EditableStatus
              availableStatuses={toOptionArray(statuses)}
              status={story.story_status}
              editable
              context="story"
              onChange={
                story.id
                  ? statusId => {
                      const fieldState = formState.$("story_status_id");
                      fieldState.set(statusId);
                      story.changeStatus(statusId);
                    }
                  : statusId => {
                      const fieldState = formState.$("story_status_id");
                      fieldState.set(statusId);
                      store.changeStatusInternal(statusId);
                    }
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={70} className="create-story-row">
        <Col lg={8} xs={24}>
          <Field name="rights_link">
            <Input />
          </Field>
        </Col>
        <Col lg={8} xs={24} />
        <Col lg={8} md={24}>
          <Field name="cms_link">
            <Input />
          </Field>
        </Col>
      </Row>
      <Row gutter={70} className="create-story-row">
        <Col className="custom-col" lg={8} md={24}>
          <Field name="all_rights">
            <Switch />
          </Field>
        </Col>
        <Col lg={8} xs={24} />
        <Col lg={8} md={24}>
          <Field name="creator">
            <Input />
          </Field>
        </Col>
      </Row>
    </Card>
  );
}

export default inject(stores => ({ store: stores.StoryEditStore }))(
  observer(MainInfo)
);
