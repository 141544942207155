/**
 * split given array into chunks
 * @param array
 * @param chunkSize
 * @returns {Array}
 */
export const arrayToChunks = (array = [], chunkSize = 2) => {
  const chunks = [];
  let index = 0;

  while (index < array.length) {
    chunks.push(array.slice(index, chunkSize + index));
    index += chunkSize;
  }

  return chunks;
};

/**
 * morph a given array with objects to array of objects which could be used for select or dropdown components
 * @param array
 * @param customField
 * @param keyName
 * @param labelName
 * @returns {{label: *, value: *}[]}
 */
export const toOptionArray = (
  array = [],
  customField = "",
  keyName = "id",
  labelName = "name"
) => {
  return array.map(entry =>
    customField
      ? {
          value: entry[keyName],
          label: entry[labelName],
          [customField]: entry[customField]
        }
      : {
          value: entry[keyName],
          label: entry[labelName]
        }
  );
};

/**
 * morph an given array to array of objects which could be used for tree select component
 * @param array
 * @param keyName
 * @param labelName
 * @param childrenKey
 * @returns {{children, title: *, value: *}[]}
 */
export const toTreeSelectOptions = (
  array = [],
  keyName = "id",
  labelName = "name",
  childrenKey = "child"
) => {
  return array.map(entry => {
    return {
      // field should be disabled if it has children (disable level 1)
      disabled: !!(entry[childrenKey] && !!entry[childrenKey].length),
      value: entry[keyName],
      title: entry[labelName],
      children: toTreeSelectOptions(
        entry[childrenKey],
        keyName,
        labelName,
        childrenKey
      )
    };
  });
};

export const toTreeSelectOptionsWithoutDisabled = (
  array = [],
  keyName = "id",
  labelName = "name",
  childrenKey = "child",
  groupKey = "group"
) => {
  const hasGroups = array.some(entry => entry[groupKey]);

  if (hasGroups) {
    const groupsMap = {};

    array.forEach(entry => {
      const group = entry[groupKey];

      if (group) {
        const parentCategoryIds = array
          .filter(
            item => item[groupKey]?.id === group.id && item[childrenKey]?.length
          )
          .map(item => item[keyName]);

        if (!groupsMap[group.id]) {
          groupsMap[group.id] = {
            value: parentCategoryIds,
            title: group.name,
            children: []
          };
        }

        const parentOption = {
          value: entry[keyName],
          title: entry[labelName],
          company_id: entry.company_id,
          children: entry[childrenKey]
            ? entry[childrenKey].map(childEntry => ({
                value: childEntry[keyName],
                title: childEntry[labelName],
                company_id: childEntry.company_id
              }))
            : []
        };

        groupsMap[group.id].children.push(parentOption);
      }
    });

    console.log(Object.values(groupsMap));

    return Object.values(groupsMap);
  }
  return array.map(entry => ({
    value: entry[keyName],
    title: entry[labelName],
    company_id: entry.company_id,
    children: entry[childrenKey]
      ? entry[childrenKey].map(childEntry => ({
          value: childEntry[keyName],
          title: childEntry[labelName],
          company_id: childEntry.company_id
        }))
      : []
  }));
};

export const toCascaderOptions = categories => {
  const groupsMap = {};
  const categoriesWithoutGroup = [];
  let hasGroup = false;

  categories.forEach(category => {
    const group = category.group ? category.group : null;

    if (group) {
      hasGroup = true;

      if (!groupsMap[group.id]) {
        groupsMap[group.id] = {
          value: group.id,
          label: group.name,
          children: []
        };
      }

      const parentOption = {
        value: category.id,
        label: category.name,
        children: category.child
          ? category.child.map(childCategory => ({
              value: childCategory.id,
              label: childCategory.name
            }))
          : []
      };

      groupsMap[group.id].children.push(parentOption);
    } else {
      const parentOption = {
        value: category.id,
        label: category.name,
        children: category.child
          ? category.child.map(childCategory => ({
              value: childCategory.id,
              label: childCategory.name
            }))
          : []
      };

      categoriesWithoutGroup.push(parentOption);
    }
  });

  if (!hasGroup) {
    return categoriesWithoutGroup;
  }

  return Object.values(groupsMap);
};

export const getRoleTitle = entry => {
  const allRoles = [];
  entry.roles.forEach(role => allRoles.push(role.title));
  if (allRoles.length > 1) {
    return allRoles.join(",");
  }
  return allRoles[0];
};

export const toOptionArrayWithEmailAndUserType = (
  array = [],
  keyName = "id",
  labelName = "name",
  email = "email"
) => {
  return array.map(entry => ({
    value: entry[keyName],
    label: entry[labelName],
    email: entry[email],
    role: getRoleTitle(entry)
  }));
};

export default {
  arrayToChunks,
  toOptionArray,
  toTreeSelectOptions,
  toTreeSelectOptionsWithoutDisabled,
  toOptionArrayWithEmailAndUserType,
  toCascaderOptions,
  getRoleTitle
};
