import React from "react";
import { Link } from "react-router-dom";
import { Col, Form, Icon, message, Row, Spin } from "antd";
import "./styles.less";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import Button from "../../common/Button";
import Assignments from "./Assignments";
import Platforms from "./Platforms";
import MainInfo from "./MainInfo";
import FormContext from "../../models/FormContext";
import StoryFormState from "../../models/forms/StoryFormState";
import getFileNameWithHash from "../../helpers/getFileNameWithHash";
import { mimeExtensionMap } from "../../helpers/mimeExtensionMap";
import getFileExtension from "../../helpers/getFileExtension";
import nameRouteMapping from "../../common/nameRouteMapping";
import asyncForEach from "../../helpers/asyncForEach";
import AccessControl from "../../models/AccessControl";

class CreateStoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = new StoryFormState(
      { ...props.StoryEditStore.formConfig },
      { options: { validateOnInit: false, showErrorsOnReset: false } }
    );

    this.state = {
      assignmentsToRemove: [],
      platformsToRemove: [],
      isAbleToViewCompanies: false
    };
  }

  componentWillMount = async () => {
    const {
      StoryEditStore: store,
      match: {
        params: { id, storyId }
      }
    } = this.props;

    if (!storyId) {
      store.load(id).then(() => {
        if (id) {
          this.form.init(toJS(store.story));
        }
        this.form.$("story_status_id").set(store.story.story_status_id);
        this.form.$("all_rights").set(store.story.all_rights);
      });
    }

    if (storyId) {
      store.load(storyId).then(() => {
        this.setState({ name: store.story.title });
        this.form.$("story_status_id").set(store.story.story_status_id);
      });
    }
    const isAbleToViewCompanies = await AccessControl.can(
      "create-story-page:view-companies"
    );
    this.setState({ isAbleToViewCompanies });
  };

  componentWillUnmount() {
    const { StoryEditStore: store } = this.props;
    store.clear();
  }

  get companyName() {
    const {
      StoryEditStore: {
        selectOptions: { company }
      }
    } = this.props;
    const { isAbleToViewCompanies } = this.state;
    if (!company || !isAbleToViewCompanies) {
      return "";
    }
    return `(${company.name})`;
  }

  handleCancel = e => {
    const {
      match: {
        params: { id }
      },
      StoryEditStore,
      history,
      location
    } = this.props;
    this.form.onReset(e);
    if (id) {
      history.push(`/story/${StoryEditStore.story.id}/view`);
    } else {
      if (location.state) {
        history.push(location.state.from);
        return;
      }
      history.push(`/story-list`);
    }
  };

  handleSubmit = () => {
    const {
      StoryEditStore: { getDigitalPlatforms, getPrintPlatforms },
      StoryEditStore,
      history,
      match: {
        params: { storyId }
      }
    } = this.props;
    const { assignmentsToRemove, platformsToRemove } = this.state;
    // validation for issue date, issue time and issue fields
    this.form.validate({ showErrors: true }).then(async ({ isValid }) => {
      let additionalValidationSuccess = true;
      this.form.$("platforms").value.forEach(platform => {
        // issue date validation (required for digital platform)
        if (
          !platform.idea &&
          getDigitalPlatforms.has(platform.platform_name_id) &&
          !platform.publication_date
        ) {
          const platformIndex = this.form
            .$("platforms")
            .value.findIndex(obj => obj === platform);
          this.form
            .$(`platforms[${platformIndex}].publication_date`)
            .invalidate("Issue date field is required.");
          additionalValidationSuccess = false;
        }
        // issue time validation (required for digital platform)
        if (
          !platform.idea &&
          getDigitalPlatforms.has(platform.platform_name_id) &&
          !platform.publication_time
        ) {
          const platformIndex = this.form
            .$("platforms")
            .value.findIndex(obj => obj === platform);
          this.form
            .$(`platforms[${platformIndex}].publication_time`)
            .invalidate("Issue time field is required.");
          additionalValidationSuccess = false;
        }
        // issue field validation (required for print platform)
        if (
          !platform.idea &&
          getPrintPlatforms.has(platform.platform_name_id) &&
          !platform.issue_id
        ) {
          const platformIndex = this.form
            .$("platforms")
            .value.findIndex(obj => obj === platform);
          this.form
            .$(`platforms[${platformIndex}].issue_id`)
            .invalidate("Issue field is required.");
          additionalValidationSuccess = false;
        }
      });
      // saving
      if (isValid && additionalValidationSuccess) {
        if (!this.form.$("attachments").value.length) {
          StoryEditStore.save({
            ...this.form.values(),
            assignments_to_remove: assignmentsToRemove,
            platforms_to_remove: platformsToRemove
          }).then(() => {
            if (storyId) {
              return StoryEditStore.linkStory(
                storyId,
                StoryEditStore.createdStoryId
              ).then(() => {
                history.push(`/story/${StoryEditStore.story.id}/view`);
              });
            }
            return history.push(`/story/${StoryEditStore.story.id}/view`);
          });
        } else {
          try {
            // 1-save story itself
            await StoryEditStore.save({
              ...this.form.values(),
              assignments_to_remove: assignmentsToRemove,
              platforms_to_remove: platformsToRemove
            });
            const attachments = this.form.$("attachments").value;
            // check for removed attachments
            const removedAttachments = [];
            attachments.forEach(attachment => {
              if (attachment.status === "removed") {
                removedAttachments.push(attachment);
              }
            });
            // 2-load signatures
            await StoryEditStore.loadS3SignatureData(StoryEditStore.story.id);
            // 3- iterate over every attachment
            await asyncForEach(attachments, async attachment => {
              const removed = removedAttachments.find(
                attach => attach.uid === attachment.uid
              );
              // skip current iteration in cases: 1-if file was uploaded earlier; 2-if file was removed in antd uploader
              if (attachment.s3_link || removed) {
                return;
              }
              // 4-create formData
              const formData = new FormData();
              const credentials = StoryEditStore.S3SignatureData.inputs;
              Object.keys(credentials).forEach(key => {
                if (key === "key") {
                  return;
                }
                formData.append(key, credentials[key]);
              });
              const name = getFileNameWithHash(attachment.name);
              formData.append(
                "key",
                `story/${StoryEditStore.story.id}/${name}`
              );
              formData.append("file", attachment); // all fields after this will be ignored
              // 5-create fileInfo object to send uploaded file information to the server
              const fileInfo = {};
              fileInfo.s3_path = `story/${StoryEditStore.story.id}/`;
              fileInfo.mime = attachment.type;
              fileInfo.size = attachment.size;
              fileInfo.filename = name;
              fileInfo.s3_filename = name;
              fileInfo.extension = mimeExtensionMap.get(attachment.type);
              if (!fileInfo.extension) {
                fileInfo.extension = getFileExtension(fileInfo.filename);
              }
              // 6-upload attachment to s3
              await StoryEditStore.uploadFile(
                formData,
                StoryEditStore.story.id,
                fileInfo
              );
            });
            // 7-redirect to story view
            if (storyId) {
              await StoryEditStore.linkStory(
                storyId,
                StoryEditStore.createdStoryId
              );
            }
            history.push(`/story/${StoryEditStore.story.id}/view`);
          } catch (error) {
            message.error(`${error}`);
          }
        }
      }
    });
  };

  addAssignmentToRemoveList = id => {
    const { assignmentsToRemove } = this.state;
    const removeList = [...assignmentsToRemove];
    removeList.push(id);
    this.setState({
      assignmentsToRemove: removeList
    });
  };

  addPlatformToRemoveList = id => {
    const { platformsToRemove } = this.state;
    const removeList = [...platformsToRemove];
    removeList.push(id);
    this.setState({
      platformsToRemove: removeList
    });
  };

  renderProperLink = () => {
    const { location } = this.props;
    const { name } = this.state;
    let pageName;
    if (location.state) {
      pageName = nameRouteMapping.get(location.state.from);
      return (
        <Link className="back-link" to={location.state.from}>
          <Icon type="arrow-left" />
          Back to the {pageName || name || "previous page"}
        </Link>
      );
    }
    return (
      <Link className="back-link" to="/">
        <Icon type="arrow-left" />
        Back to the Dashboard
      </Link>
    );
  };

  render() {
    const {
      StoryEditStore: store,
      match: {
        params: { id }
      }
    } = this.props;

    const { name } = this.state;

    const title = name && `linked to ${`“${name}“`}`;
    const pageTitle = id
      ? "Edit Story"
      : `Create Story ${title || this.companyName}`;

    return (
      <div className="create-story-container">
        {this.renderProperLink()}
        <Row>
          <Col xl={24}>
            <h2>{pageTitle}</h2>
          </Col>
        </Row>
        <Spin spinning={store.loading}>
          <Form hideRequiredMark>
            <FormContext.Provider value={this.form}>
              <MainInfo />
              <Assignments
                addAssignmentToRemoveList={this.addAssignmentToRemoveList}
              />
              <Platforms
                addPlatformToRemoveList={this.addPlatformToRemoveList}
              />
            </FormContext.Provider>
          </Form>
        </Spin>
        <div className="buttons-container">
          <Button
            text="Cancel"
            onClick={this.handleCancel}
            theme="blue-outline"
            disabled={store.loading}
          />
          <Button
            text="Save"
            onClick={this.handleSubmit}
            theme="solid-blue"
            disabled={store.loading}
          />
        </div>
      </div>
    );
  }
}

export default inject("StoryEditStore")(observer(CreateStoryPage));
