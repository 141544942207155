import { types } from "mobx-state-tree";
import Group from "./category-group";

const Category = types.model("Category", {
  id: types.number,
  name: types.string,
  parent_id: types.maybeNull(types.number),
  parent: types.maybeNull(
    types.model({
      id: types.number,
      parent_id: types.maybeNull(types.number),
      name: types.string,
      group: types.maybeNull(Group)
    })
  )
});

export default Category;

/*
category": {
          "id": 9,
          "parent_id": 4,
          "name": "Leslie Carter V",
          "parent": {
            "id": 4,
            "parent_id": null,
            "name": "Ole Prohaska"
          }
        },
 */
