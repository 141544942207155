/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { Icon, Table } from "antd";
import { observer, inject, Observer, disposeOnUnmount } from "mobx-react";
import "./styles.less";
import "../../styles/variables.less";
import { reaction } from "mobx";
import { Link } from "react-router-dom";
import createSearchString from "../../helpers/createSearchString";
import StoryListFilters from "./StoryListFilters";
import { burger } from "./icons/burger";
import { ReactComponent as linkIcon } from "../../images/icons/link.svg";
import arrow from "../../common/paginationIcons";
import EditableStatus from "../../common/EditableStatus";
import getUrlParams from "../../helpers/getUrlParams";
import AccessControl from "../../models/AccessControl";
import getPublicationDate from "../../helpers/getPublicationDate";
import BrandAndPlatformName from "../../common/BrandAndPlatformName";
import { pageSizeOptions } from "../../constants/pagination";

class StoryList extends Component {
  state = {
    isAbleToViewCompanies: false
  };

  columns = [
    {
      title: "platform",
      dataIndex: "platforms",
      key: "platforms",
      render: (platforms, row) => {
        const isMoreThenOneBrand = platforms.length > 1;
        return (
          <div className="brand_platform-td-container">
            <BrandAndPlatformName
              platforms={platforms}
              row={row}
              isAbleToViewCompanies={this.state.isAbleToViewCompanies}
            />
            {isMoreThenOneBrand && (
              <span
                className="show-more-burger"
                onClick={this.showMoreHandler(row)}
              >
                {burger}
              </span>
            )}
            <div className="link-hover">
              <div className="link-btn">
                <Icon component={linkIcon} className="link-icon" />
                <span>Link Story</span>
              </div>
            </div>
          </div>
        );
      }
    },
    {
      title: "Story Name",
      dataIndex: "title",
      key: "title",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: this.props.StoryListStore.filtersInitialValues.sorting
        .storyName,
      render: title => <span className="table-data-text">{title}</span>
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sortDirections: ["descend", "ascend"],
      render: ({ name, parent }) => {
        return (
          <span className="table-data-text">
            {parent.group && `${parent.group.name} › `}
            {parent && `${parent.name} › `}
            {name}
          </span>
        );
      }
    },
    {
      title: "story type",
      key: "content_type.name",
      dataIndex: "content_type.name",
      sorter: true,
      width: "130px",
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: this.props.StoryListStore.filtersInitialValues.sorting
        .contentType,
      render: text => <span className="table-data-text">{text}</span>
    },
    {
      title: <span className="ant-table-column-title">Date</span>,
      dataIndex: "publish_date",
      className: "publish_date_column_title",
      key: "publish_date",
      sortDirections: ["descend", "ascend"],
      render: (text, row) => {
        const isMoreThenOneBrand = row.platforms.length > 1;
        if (typeof text !== "string") {
          return (
            <div className="publish_date-td-container">
              <span className="table-data-text">{getPublicationDate(row)}</span>
              {isMoreThenOneBrand && (
                <span
                  className="show-more-burger"
                  onClick={this.showMoreHandler(row)}
                >
                  {burger}
                </span>
              )}
            </div>
          );
        }
        return <span className="table-data-text">{text}</span>;
      }
    },
    {
      title: "Status",
      dataIndex: "story_status",
      key: "story_status",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: this.props.StoryListStore.filtersInitialValues.sorting
        .status,
      render: (status, row) => {
        const {
          StoryListStore: { filters }
        } = this.props;
        const statusFilter = filters.get("statuses");
        let availableStatuses = [];
        if (statusFilter) {
          availableStatuses = statusFilter.select_options.filter(
            obj => obj.label !== "Idea"
          );
        }

        return (
          <Observer>
            {() => (
              <EditableStatus
                context="story"
                status={status}
                availableStatuses={availableStatuses}
                onChange={this.handleSingleStoryStatusChange(row)}
              />
            )}
          </Observer>
        );
      }
    },
    {
      title: "ASGMT.",
      dataIndex: "assignments_count",
      key: "assignments_count",
      sorter: true,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: this.props.StoryListStore.filtersInitialValues.sorting
        .countAssignments,
      className: "story-list-table-th-assignments",
      align: "right",
      render: text => <span className="table-data-text">{text}</span>
    },
    {
      title: "Linked Stories",
      dataIndex: "linkedStories",
      key: "linkedStories",
      className: "story-list-table-th-linked-stories",
      align: "left",
      width: "150px",
      render: (text, row) => {
        const hasParent = row.story_links_parent.length > 0;
        const hasChild = row.story_links_child.length > 0;

        return (
          <div className="linked-stories-td-container">
            <div>
              {hasParent && <div>Parent</div>}
              {hasChild && <div>Child</div>}
            </div>

            {(hasParent || hasChild) && (
              <span
                className="show-more-burger"
                onClick={this.showMoreHandler(row, true)}
              >
                {burger}
              </span>
            )}
          </div>
        );
      }
    }
  ];

  componentDidMount = async () => {
    const {
      StoryListStore,
      match: {
        params: { id }
      }
    } = this.props;
    const { company } = getUrlParams();
    const isAbleToViewCompanies = id
      ? !company
      : await AccessControl.can("story-list:view-companies");

    if (isAbleToViewCompanies) {
      this.setState({
        isAbleToViewCompanies
      });
    }
    StoryListStore.loadFilters();
    StoryListStore.setAppliedFilters();
    disposeOnUnmount(
      this,
      reaction(
        () => StoryListStore.filtersRequestParams,
        () => StoryListStore.loadStories(StoryListStore.filtersRequestParams),
        { fireImmediately: true }
      )
    );
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      StoryListStore: { setAppliedFilters },
      location: { search }
    } = this.props;
    if (prevProps.location.search !== search) {
      setAppliedFilters();
    }
  }

  handleSingleStoryStatusChange = row => status => {
    const { StoryListStore } = this.props;
    row.changeStatus(StoryListStore.statusPatch(status));
  };

  showModal = (story, isShowLinkedStories) => {
    const { isAbleToViewCompanies } = this.state;

    this.props.PlatformsModal.setPopupInfo({
      list: isAbleToViewCompanies
        ? "listOfPlatformsWithCompanies"
        : "listOfPlatforms",
      story,
      isShowLinkedStories
    });
    this.props.PlatformsModal.setVisible(true);
  };

  showMoreHandler = (targetStory, isShowMoreLinkedStories) => event => {
    event.stopPropagation();
    this.showModal(targetStory, isShowMoreLinkedStories);
  };

  tableSortingAndPaginationHandler = (pagination, tableFilters, sorter) => {
    const { history } = this.props;
    const urlParams = getUrlParams();
    urlParams.page = pagination.current;

    const namesMap = new Map([
      ["title", "storyName"],
      ["content_type.name", "contentType"],
      ["story_status", "status"],
      ["assignments_count", "countAssignments"]
    ]);

    switch (sorter.order) {
      case "ascend":
        urlParams.sortByAsc = namesMap.get(sorter.field);
        delete urlParams.sortByDesc;
        break;
      case "descend":
        urlParams.sortByDesc = namesMap.get(sorter.field);
        delete urlParams.sortByAsc;
        break;
      default:
        delete urlParams.sortByAsc;
        delete urlParams.sortByDesc;
    }
    history.push(createSearchString(urlParams));
  };

  tableRowClickHandler = record => {
    const {
      history,
      match: {
        params: { id }
      },
      StoryEditStore
    } = this.props;
    return {
      onClick: () => {
        if (id) {
          return StoryEditStore.linkStory(id, record.id).then(() => {
            history.push({
              pathname: `/story/${id}`,
              from: "story-list"
            });
          });
        }
        return history.push({
          pathname: `/story/${record.id}`,
          from: "story-list"
        });
      }
    };
  };

  paginationItemRender = (page, type) => {
    if (type === "prev") {
      return (
        <span className="pagination-arrow-back">
          <span>{arrow}</span>
          Back
        </span>
      );
    }
    if (type === "next") {
      return (
        <span className="pagination-arrow-next">
          Next
          <span>{arrow}</span>
        </span>
      );
    }
    return null;
  };

  showTotal = (totalItems, fromToArr) => {
    const {
      StoryListStore: { total }
    } = this.props;
    return `${fromToArr[0]}-${fromToArr[1]} of ${total}`;
  };

  onItemsPerPageChange = (currentPage, newSize) => {
    const { history } = this.props;
    const urlParams = getUrlParams();
    history.push(createSearchString({ ...urlParams, limit: newSize }));
  };

  render() {
    const {
      StoryListStore: {
        loadingStories,
        filters,
        loadingFilters,
        filtersInitialValues,
        currentPageNumber,
        perPage,
        total
      },
      match: {
        params: { id }
      },
      StoryListStore: store,
      history
    } = this.props;
    const { isAbleToViewCompanies } = this.state;

    return (
      <React.Fragment>
        {id && (
          <Link className="back-link" to={`/story/${id}`}>
            <Icon type="arrow-left" />
            Back to the Story
          </Link>
        )}
        <StoryListFilters
          history={history}
          filters={filters}
          loadingFilters={loadingFilters}
          filtersInitialValues={filtersInitialValues}
          isAbleToViewCompanies={isAbleToViewCompanies}
        />
        <Table
          columns={this.columns}
          className={id ? "link-story" : undefined}
          dataSource={store.stories}
          rowKey="id"
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: pageSizeOptions.low,
            onShowSizeChange: this.onItemsPerPageChange,
            pageSize: perPage,
            size: "large",
            total,
            showTotal: this.showTotal,
            itemRender: this.paginationItemRender,
            current: currentPageNumber
          }}
          expandIconAsCell={false}
          expandIconColumnIndex={-1}
          onChange={this.tableSortingAndPaginationHandler}
          onRow={this.tableRowClickHandler}
          loading={loadingStories}
        />
      </React.Fragment>
    );
  }
}

export default inject("StoryListStore", "PlatformsModal", "StoryEditStore")(
  observer(StoryList)
);
