import { types, flow, applyPatch } from "mobx-state-tree";
import moment from "moment";
import Assignment from "../common/assignment";
import TasksService from "../../services/api/tasks";
import Filter, { createFilter } from "../common/filter";
import {
  toOptionArray,
  toTreeSelectOptionsWithoutDisabled
} from "../../helpers/array-utils";
import showErrorMessage from "../../helpers/showErrorMessage";
import showMessage from "../../helpers/showMessage";

const TasksListStore = types
  .model("TasksList", {
    loadingTasks: types.optional(types.boolean, false),
    tasks: types.optional(types.array(Assignment), []),
    total: types.optional(types.number, 0),
    perPage: types.optional(types.number, 15),
    currentPageNumber: types.optional(types.number, 1),
    filtersInitialValues: types.optional(types.frozen(), {}),
    filtersRequestParams: types.optional(types.frozen(), {}),
    filters: types.optional(types.map(Filter), {}),
    loadingFilters: types.optional(types.boolean, false),
    approveContentInProgress: types.optional(types.boolean, false),
    loadingStatuses: types.optional(types.boolean, false),
    storyStatuses: types.maybeNull(Filter),
    assignmentStatuses: types.maybeNull(Filter),
    changingStatus: types.optional(types.boolean, false)
  })
  .actions(self => {
    return {
      afterCreate() {
        this.setAppliedFilters();
      },
      setAppliedFilters: function setAppliedFilters() {
        const searchParams = new URLSearchParams(window.location.search);
        // initial values
        const filtersInitialValues = {};
        filtersInitialValues.date = [];
        filtersInitialValues.sorting = {};
        filtersInitialValues.task_start_date = [];
        Array.from(searchParams.entries()).forEach(entry => {
          const [key, value] = entry;
          switch (key) {
            case "deadlineFrom":
              filtersInitialValues.date.push(moment(value));
              break;
            case "deadlineTo":
              filtersInitialValues.date.push(moment(value));
              break;
            case "sortByDesc":
              filtersInitialValues.sorting[value] = "descend";
              break;
            case "sortByAsc":
              filtersInitialValues.sorting[value] = "ascend";
              break;
            case "search":
              filtersInitialValues[key] = value.split(",");
              break;
            case "taskStartFrom":
              filtersInitialValues.task_start_date.push(moment(value));
              break;
            case "taskStartTo":
              filtersInitialValues.task_start_date.push(moment(value));
              break;
            default:
              if (Number(value)) {
                filtersInitialValues[key] = [Number(value)];
              } else {
                filtersInitialValues[key] = value
                  .split(",")
                  .map(val => Number(val));
              }
              break;
          }
        });
        self.filtersInitialValues = filtersInitialValues;

        // request params
        const filtersRequestParams = {};
        Array.from(searchParams.entries()).forEach(entry => {
          const [key, value] = entry;
          filtersRequestParams[key] = value;
        });
        self.filtersRequestParams = filtersRequestParams;
      },
      loadTasks: flow(function* loadTasks(params) {
        self.loadingTasks = true;
        try {
          const responseData = yield TasksService.getTasks(params);
          const {
            data,
            meta: { pagination }
          } = responseData;

          self.tasks = data;
          self.total = pagination.total;
          self.currentPageNumber = pagination.current_page;
          self.perPage = pagination.per_page;
          self.loadingTasks = false;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
          self.loadingTasks = false;
        }
      }),
      loadFilters: flow(function* loadFilters() {
        self.loadingFilters = true;
        try {
          const response = yield TasksService.getDataForTaskListFilters();
          self.filters.set(
            "task_types",
            createFilter("Task Type", toOptionArray(response.types))
          );
          self.filters.set(
            "story_statuses",
            createFilter("Story Status", toOptionArray(response.storyStatuses))
          );
          self.filters.set(
            "brands",
            createFilter("Brand", toOptionArray(response.brands))
          );
          self.filters.set(
            "categories",
            createFilter(
              "Category",
              toTreeSelectOptionsWithoutDisabled(response.categories)
            )
          );
          self.filters.set(
            "platforms",
            createFilter("Platform Type", toOptionArray(response.platformTypes))
          );
          self.filters.set(
            "publishTypes",
            createFilter("Schedule", toOptionArray(response.publishTypes))
          );
          self.filters.set(
            "users",
            createFilter("Assignee", response.assignees)
          );
          self.filters.set(
            "creators",
            createFilter("Task Creator", response.creators)
          );
          self.filters.set(
            "content_types",
            createFilter("Story Types", toOptionArray(response.contentTypes))
          );
          self.filters.set(
            "task_statuses",
            createFilter(
              "Task Status",
              toOptionArray(response.statuses),
              response.statuses
            )
          );

          self.loadingFilters = false;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Error: ", error);
          self.loadingFilters = false;
        }
      }),
      approveContent: flow(function* approveContent(id) {
        self.approveContentInProgress = true;
        try {
          const response = yield TasksService.approveContent(id);
          const target = self.tasks.find(
            assignment => assignment.id === response.id
          );
          target.changeStatus(
            response.assignment_status,
            response.assignment_status_id
          );
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.approveContentInProgress = false;
        }
      }),
      changeAssignmentStatus: flow(function* changeAssignmentStatus(
        assignmentId,
        statusId
      ) {
        self.changingStatus = true;
        try {
          const response = yield TasksService.changeAssignmentStatus(
            assignmentId,
            statusId
          );
          const target = self.tasks.find(
            assignment => assignment.id === assignmentId
          );

          target.changeStatus(
            response.assignment_status,
            response.assignment_status_id
          );
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.changingStatus = false;
        }
      }),
      changeStoryStatus: flow(function* changeStoryStatus(newStatus, storyId) {
        self.changingStatus = true;
        try {
          const status = yield TasksService.changeStoryStatus(
            storyId,
            newStatus
          );
          const patch = {
            op: "replace",
            path: "/story_status",
            value: status
          };

          const target = self.tasks.find(
            assignment => assignment.story_id === storyId
          );
          applyPatch(target.story, patch);
        } catch (e) {
          showMessage({});
        } finally {
          self.changingStatus = false;
        }
      }),
      loadStatuses: flow(function* loadStatuses() {
        self.loadingStatuses = true;
        try {
          const statuses = yield TasksService.getStatuses();
          self.storyStatuses = createFilter(
            "statuses",
            toOptionArray(statuses.storyStatuses),
            statuses.storyStatuses
          );
          self.assignmentStatuses = createFilter(
            "assignmentStatuses",
            toOptionArray(statuses.assignmentStatuses),
            statuses.assignmentStatuses
          );
        } catch (error) {
          showErrorMessage(error);
        } finally {
          self.loadingStatuses = false;
        }
      })
    };
  })
  .views(self => ({
    get statusPatch() {
      return statusId => {
        const { raw_values } = self.storyStatuses;
        return raw_values.find(statusEntry => statusEntry.id === statusId);
      };
    },

    get loading() {
      return (
        self.approveContentInProgress ||
        self.loadingStatuses ||
        self.changingStatus ||
        self.loadingTasks
      );
    }
  }));
const tasksListStore = TasksListStore.create({
  loadingTasks: false,
  tasks: [],
  total: 0,
  perPage: 15,
  currentPageNumber: 1,
  filtersInitialValues: {},
  filtersRequestParams: {},
  loadingFilters: false,
  filters: {},
  approveContentInProgress: false,
  loadingStatuses: false,
  changingStatus: false
});
export default tasksListStore;
