import { flow, types, applyPatch, destroy } from "mobx-state-tree";
import StoryService from "../../services/api/stories";
import StoryStatus from "../common/story-status";
import ContentType from "../common/content-type";
import Platform from "../common/platform";
import Category from "../common/category";
import showMessage from "../../helpers/showMessage";
import { getColorForStoryStatus } from "../../helpers/getColorForStatus";
import Assignment from "../common/assignment";
import EditableModel from "../common/editable-model";
import StoryAttachment from "../common/story-attachment";
import Company from "../common/company";
import LinkedStory from "../common/linked-stories";

const Story = types
  .model("Story", {
    id: types.maybeNull(types.number),
    icon: types.maybeNull(types.string),
    user_id: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    assignments_count: types.maybeNull(types.number),
    category: types.maybeNull(Category),
    category_id: types.maybeNull(types.number),
    content_type: types.maybeNull(ContentType),
    content_type_id: types.maybeNull(types.number),
    story_status: types.optional(StoryStatus, () => StoryStatus.create()),
    story_status_id: types.maybeNull(types.number),
    platforms: types.optional(types.array(Platform), []),
    assignments: types.optional(types.array(Assignment), []),
    attachments: types.optional(types.array(StoryAttachment), []),
    company: types.maybeNull(Company),
    company_id: types.maybeNull(types.number),
    children: types.optional(types.array(LinkedStory), []),
    parent: types.optional(types.array(LinkedStory), []),
    story_links_parent: types.optional(types.array(LinkedStory), []),
    story_links_child: types.optional(types.array(LinkedStory), []),
    cms_link: types.maybeNull(types.string),
    rights_link: types.maybeNull(types.string),
    all_rights: types.maybeNull(types.boolean),
    creator: types.maybeNull(types.string)
  })
  .actions(self => ({
    /**
     * change a status of the story
     */
    changeStatus: flow(function* changeStatus(newStatus) {
      try {
        const status = yield StoryService.changeStoryStatus(self, newStatus);
        const patch = {
          op: "replace",
          path: "/story_status",
          value: status
        };
        applyPatch(self, patch);
      } catch (e) {
        showMessage({});
      }
    }),
    /**
     * creates an empty platform node in tree
     */
    addNewPlatform() {
      self.platforms.push(
        Platform.create({
          id: `#${self.assignments.length}`
        })
      );
    },
    removePlatform(platform) {
      destroy(platform);
    }
  }))
  .views(self => ({
    /**
     * return a description of the platform and brand to publish on the specific date
     * @returns {*}
     */
    get listOfPlatforms() {
      return self.platforms.map(
        ({
          platform_name: { brand, platform_type },
          publication_date,
          publication_time,
          issue
        }) => {
          return [
            `${brand.name} / ${platform_type.name}`,
            issue && issue.title,
            [publication_date, publication_time].filter(Boolean).join(", ")
          ]
            .filter(Boolean)
            .join(" - ");
        }
      );
    },

    get listOfPlatformsWithCompanies() {
      return self.platforms.map(
        ({
          platform_name: { brand, platform_type },
          publication_date,
          publication_time,
          issue
        }) => {
          return [
            `${brand.name} (${self.company.name}) / ${platform_type.name}`,
            issue && issue.title,
            [publication_date, publication_time].filter(Boolean).join(", ")
          ]
            .filter(Boolean)
            .join(" - ");
        }
      );
    },

    get linkedParentStories() {
      return self.story_links_parent.map(({ story_parent }) => {
        return story_parent;
      });
    },
    get linkedChildStories() {
      return self.story_links_child.map(({ story_child }) => {
        return story_child;
      });
    },

    get categoryName() {
      const { category } = self;

      const groupName =
        category.parent && category.parent.group
          ? category.parent.group.name
          : null;
      const parentName = category.parent ? category.parent.name : null;
      const categoryName = category.name;

      if (groupName && parentName) {
        return `${groupName} › ${parentName} › ${categoryName}`;
      }

      if (parentName) {
        return `${parentName} › ${categoryName}`;
      }

      return categoryName;
    },

    get statusColor() {
      return getColorForStoryStatus(self.story_status.slug, "dot");
    },
    // fileList is necessary to display attachments on the story edit page
    get fileList() {
      return self.attachments.map(attachment => {
        const transformedAttachment = {};
        transformedAttachment.name = attachment.filename;
        transformedAttachment.uid = attachment.id;
        transformedAttachment.status = "done";
        return transformedAttachment;
      });
    }
  }));

export default types.compose(
  Story,
  EditableModel
);
